import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { Container } from "react-bootstrap";
import PaymentTable from "../util/PaymentTable";
import { Search } from "react-feather";
import EmptyImg from "../assets/images/empty.png";
import { useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import {
  request,
  scrollToTop,
  currentDate,
  getDateISOFormat,
} from "../services/utilities";
import {
    paymentChannel,
    productType,
    paymentStatus,
    transformItemsToOptions,
    AccessoriesGroupOptions
} from "../util/data";
import ConfirmPaymentModal from "../components/modals/ConfirmPaymentModal";
import ReactPaginate from "react-paginate";
import { useAuthContext } from "../context/UserProvider";
import Download from "./shared/Download";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_red.css";
import { CustomButton } from "../components/shared/CustomButton";
import { paymentsGroupOptions } from "../util/data";
import { DESIGNATION } from "../services/constants";
import { Select } from "../components/FormInput";
import ConfirmQRCodeModal from "./modals/ConfirmGenerateQRCode";
import TransactionDetailsModal from "./modals/TransactionDetailsModal";
import FilterIcon from "../assets/icons/filterIcon";
import FilterComponent from "./misc/FilterComponent";

const Payment = ({onPaymentCountChange}) => {
  // const [pending,setPending] = useState(false);
  const { userInfo, userType, userId } = useAuthContext();
  const [show, setShow] = useState(false);
  const [payments, setPayments] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isShowtransactionModal, setShowTransactionModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal toggle
  const [selectedId, setSelectedId] = useState(null); // State to keep track of selected ID
  const [selectedVcnID, setSelectedVcnID] = useState(null); // State to keep track of selected ID
  const [selectedUserName, setSelectedUserName] = useState(null); // State to keep track of selected user
  const [kpis, setKpis] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [firstItemOnPage, setFirstItemOnPage] = useState(0);
  const [lastItemOnPage, setLastItemOnPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);
  const [activateFilter, setActivateFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [params, setParams] = useState({
    product: "",
    status: "",
    channel: "",
    type: "",
    fromDate: "",
    toDate: "",
  });
  const [vcnNo, setVcnNo] = useState("");
  const [error, setError] = useState("");
  const [accessoriesItems, setAccessoriesItems] = useState([]);

  const location = useLocation().pathname;

  const clearError = () => {
    const timer = setTimeout(() => setError([]), 3000);
    return () => clearTimeout(timer);
  };

    const fetchAccessories = useCallback(
        async (searchInput) => {
            setLoading(true);
            const url = `Product/GetProducts?ProductCategoryType=${searchInput}&IsActive=${true}`;
            try {
                const rs = await request(url, "GET", true);
                setLoading(false);
                if (rs.code === "OK") {
                    setAccessoriesItems(transformItemsToOptions(rs.payload.items));
                }
            } catch (err) {
                setLoading(false);
                setError( 'Failed to get addOns kindly try again later' );
                clearError();
                console.log(err);
            }
        })


    const fetchPayments = useCallback(
        async (searchInput) => {
            setLoading(true);
            try {
                const queryParams = [];

                if (userType !== DESIGNATION.ADMIN) queryParams.push(`userId=${userId}`);
                if (params.channel) queryParams.push(`paymentChannel=${params.channel}`);
                if (params.productId) queryParams.push(`productId=${params.productId}`);
                if (params.product) queryParams.push(`productCategoryType=${params.product}`);
                if (params.status) queryParams.push(`paymentStatus=${params.status}`);
                if (params.toDate) queryParams.push(`ToDate=${params.toDate}`);
                if (params.fromDate) queryParams.push(`FromDate=${params.fromDate}`);

                // Check if searchInput is a number (for VCN ID) or not (for product name)
                if (searchInput) {
                    if (!isNaN(searchInput)) {
                        queryParams.push(`vcnID=${searchInput}`);
                    } else {
                        queryParams.push(`productName=${searchInput}`);
                    }
                }


                const queryString = queryParams.length ? `&${queryParams.join('&')}` : '';
                const url = `payment/GetPayments?pageSize=30&pageNumber=${currentPage}${queryString}`;

                const rs = await request(url, "GET", true);
                setLoading(false);

                if (rs.code === "OK") {
                    setTotalItemsCount(rs.payload.totalItemCount);
                    setLastItemOnPage(rs.payload.lastItemOnPage);
                    setFirstItemOnPage(rs.payload.firstItemOnPage);
                    setPayments(rs.payload.items);
                    setTotalPages(rs.payload.pageCount);
                }
            } catch (err) {
                setLoading(false);
                setError("Failed to get payments history, kindly try again later");
                clearError();
            }
        },
        [
            currentPage,
            userType,
            userId,
            params.channel,
            params.status,
            params.product,
            params.fromDate,
            params.toDate,
            params.productId
        ]
    );

    const generateQRCode = (event) => {
    event.stopPropagation();
    const ids = payments.map((payment) => payment.userId);
    const vcnID = payments.map((payment) => payment.vcnID);
    const names = payments.map((payment) => payment.fullName);
    setSelectedId(ids); // Set the selected ID
    setSelectedVcnID(vcnID); // Set the selected vcnId
    setSelectedUserName(names); // Set the selected user's name
    setIsModalOpen(true); // Open the modal
  };

  const fetchPaymentKPIS = useCallback(async () => {
    setLoading(true);
    try {
      const url = `payment/getPaymentCounts`;
      const rs = await request(url, "GET", true);
      setLoading(false);
      if (rs.code === "OK") {
        setKpis(rs.payload);
      }
    } catch (err) {
      setLoading(false);
      setError("Failed to get payments kpis kindly try again later");
      clearError();
    }
  }, []);

  // Export all initiated payments as excel sheet
  const handleDownloadRecords = useCallback(
    async (type, pageSize = 30) => {
      setLoading(true);
        try {
          const url = `Payment/GetPaymentDetailExcelSheet?pageSize=${pageSize}&pageNumber=${currentPage}&${
            userType !== DESIGNATION.ADMIN ? `userId=${userId}` : ``
          }&${params.channel ? `paymentChannel=${params.channel}` : ""}&${
            params.product ? `productName=${params.product}` : ""
          }&${params.status ? `paymentStatus=${params.status}` : ""}&${
            params.toDate && `ToDate=${params.toDate}`
          }&${params.fromDate && `FromDate=${params.fromDate}`}&${
            vcnNo ? `vcn  ID=${vcnNo}` : ""
          }`;
          const response = await request(url, "GET", true, null, "file");
          let outputFilename = "";
          if (userType === DESIGNATION.ADMIN) {
            if (type === "all") {
              outputFilename = `All${
                params.status && `_${paymentStatus[params.status]}`
              }${params.channel && `_${paymentChannel[params.channel]}`}${
                params.product && `_${productType[params.product]}`
              }${vcnNo && `_for_VCNId-${vcnNo}`}${
                params.fromDate &&
                `_for_(${new Date(params.fromDate).toDateString()}`
              }${
                params.toDate
                  && ` to ${new Date(params.toDate).toDateString()})`
              }_Transactions_Initiated_(${currentDate}).xlsx`;
            } else {
              outputFilename = `Page_${currentPage}${
                params.status && `_${paymentStatus[params.status]}`
              }${params.channel && `_${paymentChannel[params.channel]}`}${
                params.product && `_${productType[params.product]}`
              }${vcnNo && `_for_VCNId-${vcnNo}`}${
                params.fromDate &&
                `_for_(${new Date(params.fromDate).toDateString()}`
              }${
                params.toDate
                && ` to ${new Date(params.toDate).toDateString()})`
              }_Transactions_Initiated_(${currentDate}).xlsx`;
            }
          } else {
            if (type === "all") {
              outputFilename = `All${
                params.status && `_${paymentStatus[params.status]}`
              }${params.channel && `_${paymentChannel[params.channel]}`}${
                params.product && `_${productType[params.product]}`
              }${
                params.fromDate &&
                `_for_(${new Date(params.fromDate).toDateString()}`
              }${
                params.toDate
                && ` to ${new Date(params.toDate).toDateString()})`
              }_Transactions_Initiated_(${currentDate}).xlsx`;
            } else {
              outputFilename = `Page_${currentPage}${
                params.status && `_${paymentStatus[params.status]}`
              }${params.channel && `_${paymentChannel[params.channel]}`}${
                params.product && `_${productType[params.product]}`
              }${
                params.fromDate &&
                `_for_(${new Date(params.fromDate).toDateString()}`
              }${
                params.toDate
                && ` to ${new Date(params.toDate).toDateString()})`
              }_Transactions_Initiated_(${currentDate}).xlsx`;
            }
          }
          const blobUrl = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", outputFilename);
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setError(
            "Failed to get all initiated Payments history, kindly try again later"
          );
        }
      },
    [
      currentPage,
      userType,
      userId,
      params.channel,
      params.product,
      params.status,
      params.toDate,
      params.fromDate,
      vcnNo,
    ]
  );

  // Set the total number of records to be downloaded
  const handlePageDownload = (type) => {
    let recordSize = 0;
    if (type === "All records") {
      recordSize = 1000000;
      handleDownloadRecords("all", recordSize);
    }
    if (type === "Current page records") {
      recordSize = 30;
      handleDownloadRecords("one", recordSize);
    }
  };

  const handlePageChange = (selectedPage) => {
    scrollToTop();
    setCurrentCount(currentCount + 1);
    setCurrentPage(selectedPage.selected + 1);
  };

    const handleFilter = (e, group) => {
        if (group === "Product Type") {
            if (e !== 'Accessories') {
                setAccessoriesItems([])
                setParams({
                    product: e,
                    status: params.status,
                    channel: params.channel,
                    type: params.type,
                    fromDate: params.fromDate,
                    toDate: params.toDate,
                });
            } else {
                setParams({
                    product: e,
                    status: params.status,
                    channel: params.channel,
                    type: params.type,
                    fromDate: params.fromDate,
                    toDate: params.toDate,
                });
            }
        } else if (group === "Provider Type") {
            setParams({
                product: params.product,
                status: params.status,
                channel: e,
                type: params.type,
                fromDate: params.fromDate,
                toDate: params.toDate,
            });
        } else if (group === "Status Type") {
            setParams({
                product: params.product,
                status: e,
                channel: params.channel,
                type: params.type,
                fromDate: params.fromDate,
                toDate: params.toDate,
            });
        } else if (group === "Accessories Type") {
            setParams({
                productId: e,
                product: params.product,
                status: params.status,
                channel: params.channel,
                type: params.type,
                fromDate: params.fromDate,
                toDate: params.toDate,
            });
        } else {
            setParams({
                productId: '',
                product: "",
                status: "",
                channel: "",
                type: "",
                fromDate: "",
                toDate: "",
            });
            handleClearFilter();
        }
    };

  const handleClearFilter = () => {
    setCurrentPage(1);
    setClearFilter(false);
    setParams({
      productId: '',
      product: "",
      status: "",
      channel: "",
      type: "",
      fromDate: "",
      toDate: "",
    });
  };
  useEffect(() => {
    if (userInfo) {
      fetchPayments();
      fetchPaymentKPIS();
    }
  }, [fetchPayments, fetchPaymentKPIS, userInfo]);

  useEffect(() => {
    if (onPaymentCountChange) {
      onPaymentCountChange(payments.length);
     }
  }, [payments, onPaymentCountChange]);

  const handleActiveFilter = () => {
    if (activateFilter) {
      handleClearFilter()
    }
    setActivateFilter(!activateFilter);
  };

    const accessoryContent = (
        <Row>
            <Col>
                <Select
                    defaultOption={AccessoriesGroupOptions.group}
                    selectedValue={params[`${AccessoriesGroupOptions.filterName}`]}
                    options={accessoriesItems}
                    handleFilter={(e) => {
                        setClearFilter(true);
                        handleFilter(e, AccessoriesGroupOptions.group);
                    }}
                    overideStyle="border-primary text-black"
                />
            </Col>
        </Row>
    );
  return (
    <Container>
      <Row>
        <Card className="border-none border-white">
          <CardBody className="p-0">
            {error.length >= 1 && (
              <div
                className="float-end alert text-capitalize alert-danger alert-dismissible border-2 p-1 fade show"
                role="alert"
                style={{ background: "#fff", fontSize: "14px" }}
              >
                {error}
              </div>
            )}
            <div className="float-end">
              {loading ? <Spinner color="danger" /> : ""}
            </div>

            <Row>
              {userType === DESIGNATION.ADMIN && (
                <Col className="mb-3 float-xl-end" sm={12} md={12} xl={3}>
                  <div className="d-flex justify-content-xl-end">
                    <div className="search-box fs-6">
                      <input
                        type="number"
                        className="form-control search-input"
                        placeholder="Search with VCN Number"
                        onChange={(e) => {
                          setVcnNo(e.target.value);
                          if (e.target.value === "") {
                            fetchPayments();
                            fetchPaymentKPIS();
                          }
                        }}
                        value={vcnNo}
                      />
                      <Search size={15} className="search-icon" />
                    </div>
                  </div>
                </Col>
              )}
              {userType === DESIGNATION.ADMIN && (
                <Col xl={4} sm={12} md={12}>
                  <div className="d-flex justify-content-xl-end mb-3">
                    <div
                      className="search-box fs-6"
                      onClick={() => fetchPayments(vcnNo)}
                    >
                      <button
                        style={{ paddingLeft: "30px" }}
                        className="btn text-light"
                        id="bgred"
                        placeholder="Search with VCN NUmber"
                      >
                        Search
                      </button>
                      <Search size={15} color="white" className="search-icon" />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
            <Row className='mb-2 align-items-center' style={{ backgroundColor: '#F5F5F5' }}>
              <Col className='d-flex gap-3 m-2 align-items-center justify-content-between'>
                <button
                    name="Filter"
                    id="bgred"
                    className="login-btn x-2 d-flex align-items-center justify-content-between gap-2 py-2 px-3 h-auto w-auto text-white"
                    onClick={handleActiveFilter}
                    style={{borderRadius: '10px'}}
                >
                  <FilterIcon />
                  <span className=''>Filter</span>
                </button>

                <FilterComponent
                    filters={paymentsGroupOptions.map((option) => ({
                      label: option.group,
                      value: params[option.filterName],
                      options: option.options,
                    }))}
                    handleFilter={(e) => {
                        if (e === 'Accessories') {
                            fetchAccessories(e)
                            setClearFilter(true);
                        } else {
                            handleFilter()
                        }
                    }}
                    handleClearFilter={handleClearFilter}
                    activateFilter={activateFilter}
                    handleActiveFilter={handleActiveFilter}
                    params={params}
                    setParams={setParams}
                    dateFilter={true}
                    accessories={accessoriesItems.length > 0} // Set to true to render the accessory section
                    accessoryContent={accessoryContent} // Pass accessory content here
                />
              </Col>
            </Row>
            {/*<div className="my-3">*/}
            {/*  <div*/}
            {/*    className={`d-flex align-items-center w-100 */}
            {/*    ${*/}
            {/*      userType === DESIGNATION.ADMIN*/}
            {/*        ? "justify-content-md-between"*/}
            {/*        : "justify-content-end"*/}
            {/*    } relative`}*/}
            {/*  >*/}
            {/*    <Download*/}
            {/*      currentPage={currentPage}*/}
            {/*      isDropdownPostionEnd={userType === DESIGNATION.ADMIN ? true : false}*/}
            {/*      handleDownloadRecords={handlePageDownload}*/}
            {/*    />*/}
            {/*    {userType === DESIGNATION.ADMIN && (*/}
            {/*      <CustomButton*/}
            {/*        name="Barcode"*/}
            {/*        iconType*/}
            {/*        icon="barcode"*/}
            {/*        color="red"*/}
            {/*        action={(event) => generateQRCode(event)}*/}
            {/*      />*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*</div>*/}
            <Row className="table-responsive">
              <div className="table-container">
                {payments.length >= 1 ? (
                  <PaymentTable
                    data={payments}
                    pending={false}
                    passSelectedTransaction={(data) => {
                      setSelectedTransaction(data);
                      setShowTransactionModal(!isShowtransactionModal);
                    }}
                  />
                ) : (
                  <div>
                    <div>
                      <PaymentTable
                        data={payments.length >= 1 ? payments : []}
                        pending={false}
                      />
                      <div className="text-center pt-5">
                        <img src={EmptyImg} className="img-fluid" alt="empty" />
                        <p className="p-color pt-2">No Record to show</p>
                        {/* <p className='p-color pt-2'>Click the Add Practitioner button on the top <br/>right to add new practitioner profile</p> */}
                      </div>
                    </div>
                  </div>
                )}
                {payments.length > 1 ? (
                  <div className="d-flex mt-5  justify-content-center">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={5}
                      pageCount={totalPages}
                      previousLabel="< previous"
                      // forcePage={currentPage}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <ConfirmPaymentModal show={show} setShow={setShow} />
      <TransactionDetailsModal
        show={isShowtransactionModal}
        setShow={setShowTransactionModal}
        transaction={selectedTransaction}
      />
      <ConfirmQRCodeModal
        show={isModalOpen}
        fileName={"All Payments"}
        modalTitle={"All Payments"}
        setShow={setIsModalOpen}
        userData={{
          id: selectedId,
          vcnID: selectedVcnID,
          name: selectedUserName,
        }}
      />
    </Container>
  );
};

export default Payment;
